import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import './PaydownInfo.css'
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Card, CardTitle, Col, Row, CardText
} from 'reactstrap'
import UpdatePaydownInfo from './UpdatePaydownInfo'
import moment from 'moment'
import DatetimeRenderer from "../../Material/DatetimeRenderer"

const PaymentPlanQuery = gql`query PaymentPlanQuery($cursor: String,$first:Int!,$driverId:ID!) {
    paymentPlans: allPaymentPlans(first:$first,after: $cursor,driverId:$driverId, orderBy:["-date_added"]){
        edges{
            node{
                id
                dateAdded
                paydownAmount
                paydownOriginalBalance
                paydownFrequency
                paydownStartDate
                paydownNotes
                reason
                reasonDisplay
                status
                addedBy{
                  id
                  username
                  firstName
                  lastName
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    } }`

const UpdatePaymentPlan = gql`
    mutation updatePaymentPlan($input: UpdatePaymentPlanMutationInput!){
    updatePaymentPlan(input:$input){
        ok
        errors{
          messages
        }
    }
} `
class PaydownInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            loading: false,
            openModal: "",
        }
    }

    handleClose = (e) => {
        this.props.handleClose()
    };
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    };
    refetchQuery = (e) => {
        this.props.refetchPaymentPlanQuery()
        this.props.refetchQuery()
    };
    deletePaydown = (paymentPlan) => {
        if (paymentPlan.id) {
            this.setState({ loading: true })
            let input = { "paymentPlanId": paymentPlan.id, status: 0 }
            this.props.client.mutate({
                mutation: UpdatePaymentPlan,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updatePaymentPlan && result.data.updatePaymentPlan.ok) {
                    this.refetchQuery()
                    this.setState({ input: {}, loading: false, error: null })
                } else {
                    this.setState({ loading: false, error: "An error has occurred. Please check your input or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin." })
            })
        } else {
        }
    }
    render() {
        return (
            <div>
                <Modal isOpen={this.props.open} className="PaydownInfo">
                    <ModalHeader toggle={this.toggle}>Paydown Info</ModalHeader>
                    <ModalBody>
                        {this.props.paymentPlans && this.props.paymentPlans.edges && this.props.paymentPlans.edges.length > 0 ? this.props.paymentPlans.edges.map((paymentPlan, i) =>
                            <Row key={i}>
                                <Col xs="4">
                                    <Card body>
                                        <CardTitle>Weekly Amount</CardTitle>
                                        <CardText>${paymentPlan.node ? paymentPlan.node.paydownAmount : ""}</CardText>
                                    </Card>
                                </Col>
                                <Col xs="4">
                                    <Card body>
                                        <CardTitle>Total Payment</CardTitle>
                                        <CardText>${paymentPlan.node ? paymentPlan.node.paydownOriginalBalance : ""}</CardText>
                                    </Card>
                                </Col>
                                <Col xs="4">
                                    <Card body>
                                        <CardTitle>Status</CardTitle>
                                        <CardText>{paymentPlan.node && paymentPlan.node.status && paymentPlan.node.status === "A_1" ? "Active" : "Inactive"}</CardText>
                                    </Card>
                                </Col>
                                <Col xs="4">
                                    <Card body>
                                        <CardTitle>Start Date</CardTitle>
                                        <CardText>{paymentPlan.node && paymentPlan.node.paydownStartDate ? <DatetimeRenderer datetime={paymentPlan.node.paydownStartDate} format={moment.HTML5_FMT.DATE} /> : ""}</CardText>
                                    </Card>
                                </Col>
                                <Col xs="4">
                                    <Card body>
                                        <CardTitle>Balance Left</CardTitle>
                                        <CardText>${paymentPlan.node ? paymentPlan.node.paydownOriginalBalance : ""}</CardText>
                                    </Card>
                                </Col>
                                <Col xs="4">
                                    <Card body>
                                        <CardTitle id={"reasonEdit" + i} onClick={() => this.toggleModal("reasonEdit" + i)}>Reason <i className="fa fa-pencil-square" aria-hidden="true"> </i></CardTitle>
                                        <CardText>{paymentPlan.node && paymentPlan.node.reasonDisplay ? paymentPlan.node.reasonDisplay : "--"}</CardText>
                                    </Card>
                                    <UpdatePaydownInfo paymentPlan={paymentPlan.node} title="Edit Reason" name="reason" value={paymentPlan.node ? paymentPlan.node.reasonDisplay : ""}
                                        target={"reasonEdit" + i} id="reason" type="text" open={this.state.openModal === "reasonEdit" + i}
                                        handleClose={() => this.toggleModal("")} refetchQuery={this.refetchQuery}
                                    />
                                </Col>
                                <Col xs="4">
                                    <Card body>
                                        <CardTitle id={"paydownNotesEdit" + i} onClick={() => this.toggleModal("paydownNotesEdit" + i)}>Notes <i className="fa fa-pencil-square" aria-hidden="true"> </i></CardTitle>
                                        <CardText>{paymentPlan.node && paymentPlan.node.paydownNotes ? paymentPlan.node.paydownNotes : "--"}</CardText>
                                        <UpdatePaydownInfo paymentPlan={paymentPlan.node} title="Edit Notes" name="paydownNotes" value={paymentPlan.node ? paymentPlan.node.paydownNotes : ""}
                                            target={"paydownNotesEdit" + i} id="paydownNotes" type="text" open={this.state.openModal === "paydownNotesEdit" + i}
                                            handleClose={() => this.toggleModal("")} refetchQuery={this.refetchQuery}
                                        />
                                    </Card>
                                </Col>
                                <Col xs="4">
                                    <Card body>
                                        <CardTitle>By</CardTitle>
                                        <CardText>{paymentPlan.node && paymentPlan.node.addedBy ? paymentPlan.node.addedBy.firstName ? paymentPlan.node.addedBy.firstName + " " + paymentPlan.node.addedBy.lastName : paymentPlan.node.addedBy.username : "--"}</CardText>
                                    </Card>
                                </Col>
                                {paymentPlan.node && paymentPlan.node.status && paymentPlan.node.status === "A_1" &&
                                    <Col xs="4">
                                        <Card body>
                                            <CardTitle>{this.state.openModal === "deletePaydown" + i ? "Delete Paydown?" : "Action"}</CardTitle>
                                            {!this.state.loading &&
                                                this.state.openModal === "deletePaydown" + i ?
                                                <CardText>
                                                    <Button type="button" className="primary" size="sm" onClick={() => this.deletePaydown(paymentPlan.node)}>Yes</Button>&nbsp;&nbsp;
                                                    <Button type="button" className="ghost" size="sm" onClick={() => this.toggleModal("")}>Cancel</Button>
                                                </CardText> :
                                                <CardText><Button type="button" className="ghost" size="sm" onClick={() => this.toggleModal("deletePaydown" + i)}>Delete</Button></CardText>
                                            }
                                        </Card>
                                    </Col>
                                }
                            </Row>)
                            :
                            <Row>
                                <Col xs="12">
                                    <Card body>
                                        <CardText>No paydown available</CardText>
                                    </Card>
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.handleClose}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(PaymentPlanQuery, {
        options: ({ driverId }) => ({ variables: { driverId: driverId, first: 10 } }),
        props({ data: { paymentPlans, fetchMore, loading, variables } }) {
            return {
                paymentPlans, loading,
                refetchPaymentPlanQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    return fetchMore({
                        query: PaymentPlanQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return {
                                paymentPlans: fetchMoreResult.paymentPlans
                            }
                        },
                    })
                },
            }
        }
    }),
)(PaydownInfo)