import gql from "graphql-tag"

const AgreementsQuery = gql`query AgreementsQuery($cursor: String,$first:Int!,$carPk:Int!) {
  rentals: allRentals(first:$first,after: $cursor,carPk:$carPk,orderBy:["-start_date"]){
    edges{
      node{
        id
        stage
        driver {
          id
          name
        }
      }
    }
    pageInfo{
      endCursor
      hasNextPage
    }
  } 
}`

const AllDocuments = gql` query AllCarDocuments($car_Id: ID, $first:Int, $typesIn: [ID], $isDeleted: Boolean) {
  allCarDocuments(car_Id: $car_Id, first: $first, typesIn: $typesIn isDeleted: $isDeleted){
    edges{
      node{
        id
        documentUrl
        documentType {
          id
          typeName
        }
        name
        pk
        expiration
      }
    }
    pageInfo{
      hasNextPage
      endCursor
    }
  }
}`

const CarInfoQuery = gql`query CarInfoQuery($pk: Int!){
  car(id: $pk){
    id
    pk
    vin
    year
    dmvPlate
    canDelete
    stage
    hasTracker
    trackerStatus
    pk
    lastCheckout{
      id
      dateAdded
      carpicturesSet {
          edges {
              node {
                  id
                  pictureUrl
                  pictureType
                  description
                  dateAdded
              }
          }
      }
  }
    carModel{
      id
      make
      name
      series
      groupType{
        id
        name
      }
    }
    currentAgreement{
      id
      pk
    }
    carReservation{
      edges{
        node{
          id
          pickupDate
          rep {
            id
            username
            firstName
            lastName
          }
          driver{
            id
            name
          }
          pickupType
          deposit
          status
        }
      }
    }
    branch{
      name
    }
  }
}`

const CarInsuranceQuery = gql`query AllCarInsurancePolicies($carPk: Int!){
  allCarInsurancePolicies(carPk: $carPk, orderBy:["-id"]){
      edges{
        node{
          id
          dateAdded
          status
          insurancePolicy{
              id
              startDate
              endDate
              policyNumber
              broker{
                id
                name
              }
            }
          }
        }
      }
    }`

const CarConditionQuery = gql`query AllCarConditions($carId: ID!, $first: Int, $cursor: String){
  allCarConditions(carId:$carId, orderBy:["-id"], first: $first, after: $cursor){ 
    edges {
      node {
        id
        formData
        pk
        dateAdded
      }
    }
    pageInfo{
      endCursor
      hasNextPage
    }
  }
}`


const CarPricingQuery = gql`query CarPricingQuery($carId: ID){
  allPricingTemplates(carId: $carId){
    edges{
      node{
        id
        price
        isActive
        maximumPrice
        minimumPrice
        dateAdded
        interval
        intervalUnit
        carYearMax
        carYearMin
        carMileageMin
        carMileageMax
        visibleToCustomers
        agreementType {
          id
          branch {
            id
            defaultMileageUnit
          }
        }
      }
    }
    pageInfo{
      hasNextPage
      endCursor
    }
  }
}`

const CarDetailQuery = gql`query Car($pk: Int!){
  car(id: $pk){
    pk
    id
    customName
    canDelete
    dateAdded
    vin
    group
    lastMileage
    createdBy {
      id
      username
      firstName
      lastName
    }
    branch{
      id
      name
      customId
      defaultMileageUnit
    }
    dateAdded
    carModel{
      id
      make
      name
      series
      groupType{
        id
        name
      }
    }
    tags{
      name
    }
    cardealSet {
      edges {
        node {
          id
          carPrice
          seller {
            id
            name
          }
          buyer {
            id
            name
          }
          eventDate
          dealType
          dateAdded
          metadata
        }
      }
    }
    currentAgreement {
      startDate
      driver {
        id
        name
        email
        tlcLicense
        dmvLicense
        balance
        phone
      }
      id
      pk
    }
    carReservation{
      edges{
        node{
          id
          pickupDate
          rep {
            id
            username
            firstName
            lastName
          }
          driver{
            id
            name
          }
          pickupType
          deposit
          depositAmount
          depositScheme
          paymentMethod{
            id
            name
          }
          status
        }
      }
    }
    equipmentSet(removed:false){
      edges {
        node {
          id
          customDeviceId
          dateAdded
          removed
          equipmentItems{
            id
            pk
            name
            description
            equipmentItemsGroup {
              id
              name
            }
          }
        }
      }
    }
    activePolicy {
		  insurancePolicy {
		    broker {
		      name
		    }
		  }
		}
    year
    color
    plate{
      id
      dmvPlate
      fhv
      fhvExpiration      
      owner{
          id
          name
      }
    }
    stage
    notes
    location
    lastOilChangeDate
    mileageSinceLastOilChange
    lastDmvInspectionDate
    getDaysTillDmvInspection
    trackerStatus
    hasTracker
    trackers {
      trackingId
      trackerType
    }
    tags {
      id
      name
    }
    caractionSet{
      edges{
        node{
          id
          dateAdded
          status
          representative
          actionType
          driver{
              id
              name
          }
        }
      }
    }
    carpolicySet{
      edges{
        node{
          dateAdded
          insurancePolicy{
            dateAdded
            startDate
            policyNumber
            broker{
                name
            }
            endDate
          }
        }
      }
    }
  }
}`

const AllInsurancePoliciesQuery = gql`query AllInsurancePoliciesQuery( $searchTerm: String){
  allInsurancePolicies( searchTerm:$searchTerm){
      edges{
        node{
          id
          pk
          dateAdded
          dateModified
          policyNumber
          broker{
              id
              name
          }
          startDate
          endDate
          status
        }
      }
      pageInfo{
          hasNextPage
          endCursor
      }
  }
}`

const AllEquipmentsQuery = gql`query AllEquipmentsQuery( $carId: Int, $removed: Boolean, $equipmentType: ID){
  allEquipments(carId: $carId, removed: $removed, equipmentItems_EquipmentItemsGroup_Id: $equipmentType) {
    edges {
      node {
        id
        dateAdded
        dateRemoved
        removed
        customDeviceId
        rep{
          id
          username
          firstName
          lastName
        }
        equipmentItems{
          name
          id
          description
          equipmentItemsGroup {
            id
            name
          }
        }
      }
    }
  }
}`

const AllCarDocumentTypesQuery = gql`
  query AllCarDocumentTypesQuery{
      allCarDocumentTypes(isActive:true){
          edges{
              node{
                typeName
                id
                requiresExpiration
              }
          }
    }
  }
`

const AllEquipmentItemsGroup = gql`
  query AllEquipmentItemsGroup{
    allEquipmentItemsGroup {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const AllEquipmentItems = gql`{
  allEquipmentItems{
    edges {
      node {
        id
        pk
        name
        equipmentItemsGroup {
          id
          name
        }
      }
    }
  }
}`

const AllCarModelGroupTypes = gql`{
  allCarModelGroupTypes {
    edges {
      node {
        id
        name
      }
    }
  }

}`

const AllCarModelsQuery = gql`query AllCarModelsQuery{
  allCarModels{
      edges {
          node {
              id
              pk
              name
              make
              series
              groupType{
                  id
                  name
              }
          }
      }
  }
}`

const AllBranchesQuery = gql`query AllBranchesQuery{
  allBranches{
      edges {
          node {
              id
              pk
              name
          }
      }
  }
}`


export {
  AgreementsQuery,
  AllDocuments,
  CarInfoQuery,
  CarDetailQuery,
  CarInsuranceQuery,
  CarPricingQuery,
  AllInsurancePoliciesQuery,
  AllEquipmentsQuery,
  AllCarDocumentTypesQuery,
  AllEquipmentItemsGroup,
  AllEquipmentItems,
  AllCarModelGroupTypes,
  AllCarModelsQuery,
  AllBranchesQuery,
  CarConditionQuery
}
