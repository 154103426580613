import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import './DriversListContainer.css'
import DriversList from './DriversList'
import { Container, Row, Col, Button, Nav, NavItem } from 'reactstrap'
import DriverListHeader from "./DriverListHeader"
import ManagePreferenceHOC from "../../Material/ManagePreferenceHOC"

const tabs = [
    { id: "#active", name: "Active Drivers", filters: { stages: [6], orderBy: ["-in_office", "-id"] } }, { id: "#all", name: "All Drivers", filters: { orderBy: ["-in_office", "-id"] } },
]
const tableHeaders = [
    { id: "id", name: "Driver Info", sortable: true, visible: true, showInPicker: false, positonIsFixed: true },
    { id: "branch__name", name: "Branch", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "weekly_billing_notes", name: "Billing Notes", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "tags", name: "Tags", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "assigned_to__name", name: "Assigned Rep", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
]

class DriversListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: null,
            filterValues: {},
            showLoading: false,
            openModal: "",
            filtersSelected: 0,
            selectedDrivers: [],
            refetchDrivers: false,
        }
    }

    updateOrder = (string) => {
        let order = [...this.state.filterValues.orderBy]
        if (!order.includes(string) && !order.includes("-" + string)) {
            order = [order.find(item => item.includes("-in_office")), string]
        } else {
            let index = order.indexOf(string)
            if (order.indexOf(string) > -1) {
                order[index] = "-" + string
            } else {
                index = order.indexOf("-" + string)
                index > -1 && (order[index] = string)
            }
        }
        this.setState({ filterValues: { ...this.state.filterValues, orderBy: order } })
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }

    componentDidMount() {
        if (window.location.hash && tabs.map(item => item.id).includes(window.location.hash)) {
            let tabIndex = tabs.findIndex(tab => tab.id === window.location.hash)
            this.setState({ activeTab: tabs[tabIndex].name })
        } else {
            window.location.hash = tabs[0].id
            this.setState({ activeTab: tabs[0].name })
        }
    }

    changeTab = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (value !== this.state.activeTab) {
            let tabIndex = tabs.findIndex(tab => tab.name === value)
            window.location.hash = tabs[tabIndex].id
            this.setState({ [name]: value })
        }
    }

    render() {
        return (
            <Container fluid className="DriversListContainer">
                <Nav pills className="available-car-wrap">
                    {tabs.map(tab =>
                        <NavItem key={tab.id}>
                            <Button color="primary" name={"activeTab"} value={tab.name} className={this.state.activeTab === tab.name ? "active" : "inactive"} onClick={this.changeTab}>
                                {tab.name}
                            </Button>
                        </NavItem>
                    )}
                </Nav>
                <DriverListHeader
                    activeTab={this.state.activeTab}
                    filterValues={this.state.filterValues}
                    setFilterValues={(filterValues) => this.setState({ filterValues })}
                    defaultFilters={this.state.activeTab && tabs.find(tab => tab.name === this.state.activeTab).filters}
                    selectedDrivers={this.state.selectedDrivers}
                    resetSelectedDrivers={() => this.setState({ selectedDrivers: [] })}
                    refetchQuery={() => this.setState({ refetchDrivers: !this.state.refetchDrivers })}
                    currentUser={this.props.currentUser}
                    columnConfig={this.props.columnConfig}
                    preferenceType={"driver"}
                    refetchPreferences={this.props.refetchPreferences}
                    {...this.props}
                />
                <Row>
                    <Col>
                        <DriversList
                            currentUser={this.props.currentUser}
                            orderBy={this.state.filterValues && this.state.filterValues.orderBy || []}
                            updateOrder={this.updateOrder}
                            activeTab={this.state.activeTab}
                            columnConfig={this.props.columnConfig}
                            selectedDrivers={this.state.selectedDrivers}
                            setSelectedDrivers={(selectedDrivers) => this.setState({ selectedDrivers })}
                            pk={this.state.filterValues && this.state.filterValues.pk ? this.state.filterValues.pk : null}
                            email={this.state.filterValues && this.state.filterValues.email ? this.state.filterValues.email : null}
                            phone={this.state.filterValues && this.state.filterValues.phone ? this.state.filterValues.phone : null}
                            minBalance={this.state.filterValues && this.state.filterValues.minBalance ? parseFloat(this.state.filterValues.minBalance) : null}
                            maxBalance={this.state.filterValues && this.state.filterValues.maxBalance ? parseFloat(this.state.filterValues.maxBalance) : null}
                            inOffice={this.state.filterValues && this.state.filterValues.inOffice !== null ? this.state.filterValues.inOffice : null}
                            isBlocked={this.state.filterValues && this.state.filterValues.isBlocked !== null ? this.state.filterValues.isBlocked : null}
                            hasBalanceWriteOff={this.state.filterValues && this.state.filterValues.hasBalanceWriteOff !== null ? this.state.filterValues.hasBalanceWriteOff : null}
                            hasPaymentPlan={this.state.filterValues && this.state.filterValues.hasPaymentPlan !== null ? this.state.filterValues.hasPaymentPlan : null}
                            tlcLicense={this.state.filterValues && this.state.filterValues.tlcLicense ? this.state.filterValues.tlcLicense : null}
                            dmvLicense={this.state.filterValues && this.state.filterValues.dmvLicense ? this.state.filterValues.dmvLicense : null}
                            assignedTo={this.state.filterValues && this.state.filterValues.assignedTo ? this.state.filterValues.assignedTo : null}
                            tags={this.state.filterValues && this.state.filterValues.driverTags ? this.state.filterValues.driverTags : null}
                            searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                            stages={this.state.filterValues && this.state.filterValues.stages ? this.state.filterValues.stages : null}
                            carStatusIn={this.state.filterValues && this.state.filterValues.carStatusIn ? this.state.filterValues.carStatusIn : null}
                            refetchDrivers={this.state.refetchDrivers}
                            setRefetchQuery={() => this.setState({ refetchDrivers: !this.state.refetchDrivers })}
                            promoNames={this.state.filterValues && this.state.filterValues.promoNames != null ? this.state.filterValues.promoNames : null}
                            listingType="drivers"
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default compose(
    withApollo,
    ManagePreferenceHOC("driver", tableHeaders)
)(DriversListContainer)
