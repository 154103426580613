import React, { Component } from 'react'
import { compose } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo, graphql } from 'react-apollo/index'
import { Col, Row, Input, InputGroup, InputGroupAddon, InputGroupText, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import moment from "moment"
import { DateRange } from "react-date-range"
import { debounce } from 'lodash'
import ListingFilters from "../Material/ListingFilters"
import renderEnumToInt from "../Functions/renderEnumToInt"
import toTitleCase from "../Functions/toTitleCase"
import MassMessageModal from "../Material/MassMessageModal"
import CarIssueModal from "./CarIssueModal"


const AllCarIssueCategoriesQuery = gql`
  query AllCarIssueCategories{
    optionsList:allCarIssueCategories(orderBy:["category"]){
        edges {
        node {
            id
            category
            description
            type
        }
        }
    }
  }
`
const AllVendorsQuery = gql`
  query AllVendors{
    optionsList:allVendors(isActive:true, orderBy:["name"]){
      edges {
        node {
          id
          name
          address
        }
      }
    }
  }
`

const AllCarLocationsQuery = gql`query AllCarLocations{
    optionsList:allCarLocations(orderBy:["name"]){
        edges {
            node {
                id
                name
            }
        }
    }
  }
`
const CarIssueStatuses = gql`query CarIssueStatuses{
    optionsList:__type(name:"CarIssuesStatus"){
      states: enumValues{
        name
        description
      }
    }
  }`

const JobStatuses = gql`query JobStatuses{
    optionsList:__type(name:"JobStatus"){
      states: enumValues{
        name
        description
      }
    }
  }`

const CarStages = gql`query CarStages{
    optionsList: __type(name: "CarStage") {
        states: enumValues {
            name
            description
        }
    }
}`

const RepsQuery = gql`
    query AllRepsQuery($groups:[String], $permissions:[String], $orderBy:[String]){
      optionsList: allReps(isStaff:true, isActive:true, groups: $groups, permissions: $permissions, orderBy:$orderBy){
        edges{
          node{
            id
            name
            username
            firstName
            lastName
            email
            isBilling
            isFrontOffice
            assignedCount
          }
        }
      }
    }
`

const AllJobTypesQuery = gql`
  query AllJobTypes{
    allJobTypes{
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

class MaintenanceListHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null,
            selectedCategories: null,
        }
    }
    handleCategoryUpdate = (category) => {
        let selectedCategories = []
        if (category && category.value) {
            if (category.value == "Other") {
                selectedCategories = this.props.optionsList && this.props.optionsList.edges && this.props.optionsList.edges.filter(item => category['label'].toLowerCase !== (item.node.type.toLowerCase())).map(item => item.node.id)
            } else {
                selectedCategories = this.props.optionsList && this.props.optionsList.edges && this.props.optionsList.edges.filter(item => category.label.toLowerCase() === (item.node.type.toLowerCase())).map(item => item.node.id)
            }
            this.props.setFilterValues({ ...this.props.filterValues, selectCategories: category.value, selectedCategories })
            this.setState({ selectedCategories: category.label })
            this.toggleModal("")
        }
    }
    getFilterConfigurations = (activeTab) => {
        return [
            { type: "select", name: "selectedStatuses", title: "Issue Status", optionsQuery: CarIssueStatuses, placeholder: "Filter By Issue Status", valueSelector: "name", labelSelector: "description", isMulti: true, showFilter: activeTab === "Issues" },
            { type: "select", name: "selectedStatuses", title: "Job Status", optionsQuery: JobStatuses, placeholder: "Filter By Job Status", valueSelector: "name", labelSelector: "description", extractValue: true, isMulti: true, showFilter: activeTab === "Jobs" },
            { type: "select", name: "selectedStages", title: "Car Stage", optionsQuery: CarStages, placeholder: "Filter By Car Stage", valueSelector: "name", labelSelector: "description", extractValue: true, isMulti: true, showFilter: true },
            { type: "select", name: "selectCategories", title: "Car Issue Type", optionsQuery: AllCarIssueCategoriesQuery, placeholder: "Filter By Issue Type", handleSelection: this.handleCategoryUpdate, valueSelector: "category", labelSelector: "category", showFilter: activeTab === "Issues" },
            { type: "select", name: "selectedSubCategories", title: "Car Issue Category", optionsQuery: AllCarIssueCategoriesQuery, placeholder: "Filter By Issue Category", isMulti: true, valueSelector: "id", labelSelector: "category", isDisabled: true, showFilter: activeTab === "Issues" },
            { type: "select", name: "jobTypes", title: "Job Types", optionsQuery: AllJobTypesQuery, placeholder: "Filter By Job Types", valueSelector: "id", labelSelector: "name", isMulti: true, showFilter: activeTab === "Jobs", },
            { type: "select", name: "selectedVendors", title: "Vendor", optionsQuery: AllVendorsQuery, valueSelector: "id", labelSelector: "name", isMulti: true, placeholder: "Filter By Vendors", showFilter: ["Jobs", "Issues"].includes(activeTab) },
            { type: "select", name: "selectedReps", title: "Assigned To", optionsQuery: RepsQuery, variables: [{ groups: ["Maintenance"] }], valueSelector: "id", labelSelector: "firstName", isMulti: true, placeholder: "Assigned To Anyone", showFilter: activeTab === "Issues" },
            { type: "select", name: "selectedLocations", title: "Car Location", optionsQuery: AllCarLocationsQuery, valueSelector: "id", labelSelector: "name", isMulti: true, placeholder: "Filter By Location", showFilter: activeTab === "Issues" },
        ]
    }
    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    updateSearchTerm = (searchTerm) => {
        this.props.setFilterValues({ ...this.props.filterValues, searchTerm })
    }

    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            this.props.setFilterValues({ ...this.props.filterValues })
        }
    }
    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = filterConfigs.name === "selectedCategories" ? filterConfigs.options.map(option => option.options).flat(1).filter(item => options.includes(item.id)).map(item => item.label) : value.map(item => options.find(option => option.value == item) && options.find(option => option.value == item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean")
            value = toTitleCase(value.toString())
        if (filterConfigs.name === "selectCategories") {
            value = this.state.selectedCategories
        }
        return Array.isArray(value) ? value.join(", ") : value
    }

    setFilters = () => {
        let conf = this.getFilterConfigurations(this.props.activeTab)
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.client.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0) {
                        if (filter.name === "selectCategories") {
                            options = [... new Set(result.data.optionsList.edges.map(option => option.node.type))].map(option => ({ value: option.charAt(0).toUpperCase() + option.slice(1).toLowerCase(), label: option.charAt(0).toUpperCase() + option.slice(1).toLowerCase() }))
                        } else {
                            options = result.data.optionsList.edges.map(options => options.node && ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }))
                        }
                    }
                    else if (result && result.data.optionsList && result.data.optionsList.states && result.data.optionsList.states.length > 0) {
                        options = result.data.optionsList.states.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? renderEnumToInt(options[filter.valueSelector]) : options[filter.valueSelector], label: options[filter.labelSelector] }))
                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }

    componentDidUpdate(prevProps) {
        let filterValues = this.props.filterValues
        if (prevProps.filterValues && this.props.filterValues && this.props.filterValues.selectedCategories && prevProps.filterValues.selectedCategories !== this.props.filterValues.selectedCategories) {
            let selectedSubCategoriesIndex = this.state.configurations.findIndex(conf => conf.name === "selectedSubCategories")
            let selectedSubCategories = this.state.configurations[selectedSubCategoriesIndex]
            selectedSubCategories.options = this.props.optionsList.edges.filter(item => this.props.filterValues.selectedCategories.includes(item.node.id)).map(item => ({ value: item.node.id, label: item.node.category }))
            selectedSubCategories.isDisabled = false
            let conf = this.state.configurations
            conf[selectedSubCategoriesIndex] = selectedSubCategories
            this.setState({ configurations: conf })
            if (filterValues.hasOwnProperty("selectedSubCategories")) {

                delete filterValues["selectedSubCategories"]
                this.props.setFilterValues({ ...filterValues })
            }
        }
        if (this.props.filterValues.selectedCategories && !this.props.filterValues.selectCategories) {
            delete filterValues["selectedCategories"]
            this.props.setFilterValues({ ...filterValues })
        }
    }
    componentDidMount() {
        this.setFilters()
    }
    applyDateFitler = () => {
        this.setState({ showDateRangePopup: true })
        this.props.setFilterValues({ ...this.props.filterValues, selectionRange: [{ startDate: new Date(), endDate: null, key: 'selection' }] })
    }
    clearDateFilter = () => {
        let filterValues = this.props.filterValues
        filterValues = delete filterValues['selectionRange']
        this.props.setFilterValues({ ...this.props.filterValues })
    }

    render() {
        return (
            <Row className="pb-2">
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props.filterValues} setFilterValues={this.props.setFilterValues}
                        filters={this.state.configurations}
                    />}
                {this.state.openModal === "MassMessageModal" && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal")} open={this.state.openModal === "MassMessageModal"} drivers={this.props.selectedDrivers && this.props.selectedDrivers.length > 0 ? this.props.selectedDrivers : null} />}
                {this.state.openModal === "createCarIssue" && <CarIssueModal open={this.state.openModal === "createCarIssue"} handleClose={() => this.toggleModal("createCarIssue")} refetchQuery={this.props.refetchQuery} />}
                <Col xs={4}>
                    <span className="driver-search-filter">
                        <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.updateSearchTerm(e.target.value)} />
                        <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                    </span>
                </Col>
                <Col xs={3}>
                    <InputGroup className="mb-0 date-range-group">
                        <InputGroupAddon addonType="prepend">
                            <a id="customDateSelection" onClick={this.applyDateFitler}><InputGroupText className="h-100"><i className="fa fa-calendar" aria-hidden="true"></i></InputGroupText></a>
                        </InputGroupAddon>
                        <Input disabled placeholder="Select Custom Date Range" value={this.props.filterValues && this.props.filterValues.selectionRange && this.props.filterValues.selectionRange[0].startDate && this.props.filterValues.selectionRange[0].endDate ? moment(this.props.filterValues.selectionRange[0].startDate).tz("America/New_York").format("ddd, MMM D YYYY") + " - " + moment(this.props.filterValues.selectionRange[0].endDate).tz("America/New_York").format("ddd, MMM D YYYY") : ""} />
                        {this.props.filterValues && this.props.filterValues.selectionRange && this.props.filterValues.selectionRange[0].startDate && this.props.filterValues.selectionRange[0].endDate && <i className="fa fa-times-circle-o reset-date-range" onClick={() => this.clearDateFilter()} aria-hidden="true"></i>}
                        <Popover placement="bottom" isOpen={this.state.showDateRangePopup} target="customDateSelection" toggle={() => this.setState({ showDateRangePopup: false })}>
                            <PopoverHeader className="text-center">Select Custom Date Range</PopoverHeader>
                            <PopoverBody>
                                <DateRange ranges={this.props.filterValues && this.props.filterValues.selectionRange} onChange={(ranges) => this.props.setFilterValues({ ...this.props.filterValues, selectionRange: [ranges.selection] })} editableDateInputs={true} moveRangeOnFirstSelection={true} />
                            </PopoverBody>
                        </Popover>
                    </InputGroup>
                </Col>
                <Col xs={5} className="text-right mt-2">
                    {this.props.selectedDrivers && this.props.selectedDrivers.length > 0 &&
                        <>
                            <a className="driver-list-button" onClick={() => this.toggleModal("MassMessageModal")}>
                                Message Drivers | <i className="fa fa-commenting-o" aria-hidden="true"></i>
                            </a>&nbsp;&nbsp;&nbsp;
                        </>
                    }
                    {this.props.activeTab === "Issues" &&
                        <>
                            <a className="driver-list-button" onClick={() => this.toggleModal("createCarIssue")}>
                                Create Car Issue | <i className="fa fa-plus" aria-hidden="true"></i>
                            </a>&nbsp;&nbsp;&nbsp;
                        </>
                    }
                    <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("viewFilters")}>
                        Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).length || ""}
                    </a>
                </Col>
                <Col xs={12} className="mt-2">
                    {Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {this.state.configurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(this.state.configurations.find(setting => setting.name == key), this.props.filterValues[key])}
                        </span>
                    )}
                    {this.props.selectedDrivers && this.props.selectedDrivers.length > 0 && <span className="search-filter-preview">
                        <span> <i className="fa fa-times-circle" onClick={this.props.resetSelectedDrivers}></i> &nbsp;&nbsp; Selected Drivers</span>
                        {this.props.selectedDrivers.length}
                    </span>}
                </Col>
            </Row>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllCarIssueCategoriesQuery, { options: { fetchPolicy: 'cache-first' }, props({ data: { loading, optionsList } }) { return { loading, optionsList } } }),
)(MaintenanceListHeader)
