'use strict'
import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Badge, Button, Table, Input, UncontrolledTooltip, Progress, CardBody, Card } from 'reactstrap'
import { Link } from 'react-router-dom'
import "../index-dark.css"
import DriverUpdateTagsPopover from "../Drivers/Driver/Tags/DriverUpdateTagsPopover"
import UpdateLeadInfo from "./UpdateLeadInfo"
import { AllCustomerLeadsQuery, AllPalencaAccountsQuery } from "./Queries"
import DatetimeRenderer from "../Material/DatetimeRenderer"
import Languages from "../Material/Language.json"
import "../Material/ListingHeader.css"
import "./index.css"
import toTitleCase from "../Functions/toTitleCase"
import datetimeToString from "../Functions/datetimeToString"
import MassMessageModal from "../Material/MassMessageModal"
import DocumentModal from "../Material/DocumentModal/DocumentModal"
import LeadPreviewModal from "./LeadPreviewModal"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
import DriverNotes from "../ApplicationsNew/DriverNotes"
import { ReactComponent as InOffice } from "../assets/images/icons/in-office.svg"
import ConfirmationPopup from "../Material/ConfirmationPopup"
import {GetConfigurationSettings, HasPermissionsQuery} from "../Functions/querys"
import { ReactComponent as PalencaIcon } from '../assets/images/icons/palenca.svg'


const ArchiveCustomerLead = gql`
    mutation archiveCustomerLead($input: ArchiveLeadMutationInput!){
    archiveCustomerLead(input:$input){
        ok
        errors{
          messages
        }
    }
} `

const CreatePalencaUserAndSendInvitation = gql`
    mutation createPalencaUserAndSendInvitation($input: CreatePalencaUserAndSendInvitationMutationInput!){
        createPalencaUserAndSendInvitation(input: $input){
            ok
            errors{
                messages
            }
        }
    }
`

class PalencaLogo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: "",
            toolTipMessage: "Palenca Is Not Connected"
        }
    }

    sendPalencaWhatsappInvitation = async (driverId) => {
        if (this.state.toolTipMessage === "Mensage Enviado!") return
        if (!driverId) return this.setState({ error: "Missing Required Driver ID" })
        const input = {
            id: driverId
        }
        try {
            const response = await this.props.client.mutate({
                mutation: CreatePalencaUserAndSendInvitation,
                variables: { input }
            })
            const responseStatus = response.data.createPalencaUserAndSendInvitation.ok

            if (!responseStatus) {
                const errorMessage = response.data.createPalencaUserAndSendInvitation.errors[0].messages[0]

                return this.setState({ error: errorMessage })
            }
            return this.setState({ toolTipMessage: "Mensage Enviado!" })
        } catch (err) {

        }


    }

    render() {
        const { lead } = this.props
        return (
            <>
                &nbsp;<PalencaIcon id={"palenca" + lead.node.pk} width={18} height={18} fill="#198754" style={{ cursor: "pointer" }} onClick={() => { this.sendPalencaWhatsappInvitation(lead.node.customer.id) }} />
                <UncontrolledTooltip target={"palenca" + lead.node.pk}>{this.state.error ? this.state.error : this.state.toolTipMessage}</UncontrolledTooltip>
            </>
        )
    }
}

class LeadsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            loading: "",
            error: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName)
            this.setState({ openModal: "" })
        else
            this.setState({ openModal: modalName })
    }

    toggleSelectAllClick = () => {
        if (this.props.allCustomerLeads && this.props.allCustomerLeads.edges && this.props.allCustomerLeads.edges.length > 0 && !this.state.selectAll) {
            this.props.setSelectedLeads(this.props.allCustomerLeads.edges.map(n => n.node))
        } else {
            this.props.setSelectedLeads([])
        }
        this.setState({ selectAll: !this.state.selectAll })
    };

    updateLeadsSelection = (leadsObject) => {
        let selectedLeads = this.props.selectedLeads
        if (selectedLeads && selectedLeads.map(item => item.id).includes(leadsObject.id)) {
            selectedLeads = selectedLeads.filter(item => item.id !== leadsObject.id)
        } else {
            selectedLeads.push(leadsObject)
        }
        this.props.setSelectedLeads(selectedLeads)
    };

    renderLangCode(langCode) {
        if (Languages.hasOwnProperty(langCode)) {
            return `${Languages[langCode].name} - ${Languages[langCode].nativeName}`
        } else {
            return null
        }
    }

    archiveLead = (leadId) => {
        if (leadId) {
            this.setState({ loading: true })
            let input = { "id": leadId }
            this.props.client.mutate({
                mutation: ArchiveCustomerLead,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.archiveCustomerLead && result.data.archiveCustomerLead.ok) {
                    this.props.refetchAllCustomerLeadsQuery()
                    this.toggleModal("")
                    this.setState({ input: {}, loading: false, error: null })
                } else if (result.data && result.data.archiveCustomerLead.errors && result.data.archiveCustomerLead.errors.length > 0) {
                    this.setState({ loading: false, error: result.data.archiveCustomerLead.errors[0].messages[0] })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: err })
            })
        } else {
            this.setState({ error: "Missing Required Field ID" })
        }
    }

    sendPalencaWhatsappInvitation = async (driverId) => {
        if (!driverId) return this.setState({ error: "Missing Required Driver ID" })
        const input = {
            id: driverId
        }
        try {
            const response = await this.props.client.mutate({
                mutation: CreatePalencaUserAndSendInvitation,
                variables: { input }
            })
            const responseStatus = response.data.createPalencaUserAndSendInvitation.ok
            console.log(responseStatus)
            if (!responseStatus) {
                const errorMessage = response.data.createPalencaUserAndSendInvitation.errors[0].messages[0]
                console.log(errorMessage)
                this.setState({ error: errorMessage })
            }
            console.log("RESPONSE")
            console.log(response)
        } catch (err) {
            console.log("ERROR")
            console.log(err)

        }


    }


    render() {
        const { columnConfig } = this.props
        return (
            <PrimaryListingContainer listLoading={this.props.leadListLoading} totalFetchedRows={this.props.allCustomerLeads && this.props.allCustomerLeads.edges.length} pageInfo={this.props.allCustomerLeads && this.props.allCustomerLeads.pageInfo} loadMoreEntries={this.props.allCustomerLeads && this.props.allCustomerLeads.pageInfo && this.props.allCustomerLeads.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchAllCustomerLeadsQuery}>
                <Table responsive>
                    <thead>
                        <tr>
                            <th onClick={this.toggleSelectAllClick} className="select-all-checkbox"> {this.state.selectAll ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                            {columnConfig && columnConfig.filter(item => item.visible && (!this.props.statuses.includes("Need Review") || !["missing_docs", "tags"].includes(item.id))).filter(item => !(this.props.statuses && this.props.statuses.length === 1 && this.props.statuses.includes("Onboarding") && item.id === "status")).map(tableHeader => {
                                return (
                                    tableHeader.sortable ?
                                        <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{this.props.orderBy && this.props.orderBy.includes(tableHeader.id) ? <i className="fa fa-sort-asc"></i> : this.props.orderBy.includes("-" + tableHeader.id) ? <i className="fa fa-sort-desc"></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{tableHeader.name}</th> :
                                        <th key={tableHeader.id}>{this.props.statuses.includes("Onboarding") && tableHeader.id == "missing_docs" ? "Required Insurance Docs" : tableHeader.name}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.allCustomerLeads && this.props.allCustomerLeads.edges.length > 0 ? this.props.allCustomerLeads.edges.map((lead, i) =>
                            <tr key={lead.node.id} className={lead.node.customer && lead.node.customer.inOffice && "driver-in-office-row"}>
                                <td scope="row" className="listing-checkbox-wrapper">
                                    {!this.props.selectedLeads.map(lead => lead.id).includes(lead.node.id) && <strong className="listing-counter">{i + 1}</strong>}
                                    <span className="listing-checkbox" onClick={() => this.updateLeadsSelection(lead.node)}>
                                        {this.props.selectedLeads.map(lead => lead.id).includes(lead.node.id) ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o unchecked" aria-hidden="true"></i>}
                                    </span>
                                </td>
                                {columnConfig && columnConfig.filter(item => item.visible && (!this.props.statuses.includes("Need Review") || !["missing_docs", "tags"].includes(item.id))).filter(item => !(this.props.statuses && this.props.statuses.length === 1 && this.props.statuses.includes("Onboarding") && item.id === "status")).map(column =>
                                    <>
                                        {column.id === "date_added" &&
                                            <td>
                                                <DatetimeRenderer datetime={lead.node.dateAdded} placement={"left"} />
                                                <br /><small><i className="fa fa-history" aria-hidden="true"></i>&nbsp;&nbsp;{datetimeToString(lead.node.dateAdded)}</small>
                                            </td>
                                        }
                                        {column.id === "id" &&
                                            <td>
                                                <div className="driver-detail-block">
                                                    <div>
                                                        {lead.node.customer && <><small>
                                                            {lead.node.customer.dmvLicense || "--"}
                                                            {(lead.node.customer && lead.node.branch.branchSettings.edges.some((branchSettings) => branchSettings.node.value === "true" && branchSettings.node.key.name === "ENABLE_PALENCA_WORKFLOW")) &&
                                                                lead.node.customer.palencaAccounts && lead.node.customer.palencaAccounts.length < 1 && this.props.viewType === "applicationView" &&
                                                                <PalencaLogo key={i} lead={lead} client={this.props.client} />
                                                            }
                                                        </small><br /></>}
                                                        {this.state.openModal === "MassMessageModal" + i && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal" + i)} open={this.state.openModal === "MassMessageModal" + i} drivers={[lead.node.customer.id]} />}
                                                        {this.state.openModal === "driverDocuments" + i && <DocumentModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "driverDocuments" + i} objectType="driver" id={lead.node.customer.id} currentUser={this.props.currentUser} />}
                                                        {this.state.openModal === "LeadInfoModal" + i && <LeadPreviewModal id={lead.node.id} open={this.state.openModal === "LeadInfoModal" + i} handleClose={() => this.toggleModal("")} refetchAllCustomerLeadsQuery={this.props.refetchAllCustomerLeadsQuery} currentUser={this.props.currentUser} />}
                                                        {this.state.openModal === "archiveLead" + i &&
                                                            <ConfirmationPopup
                                                                open={this.state.openModal === "archiveLead" + i}
                                                                message={"Are you sure you want to archive the lead?"}
                                                                loading={this.state.loading}
                                                                handleClose={() => this.toggleModal("")}
                                                                action={"Yes"}
                                                                confirmAction={() => this.archiveLead(lead.node ? lead.node.id : null)}
                                                                color="danger"
                                                                error={this.state.error ? this.state.error : ""}
                                                            />
                                                        }
                                                        {!this.state.openModal && <UncontrolledTooltip delay={{ "show": 400, "hide": 200 }} target={"leadInfo" + i} placement="right" autohide={false} className="bos-custom-tooltip">
                                                            <label>{lead.node.fullName}&nbsp;{lead.node.isBlocked && <><i id={"isBlocked" + i} class="fa fa-ban text-danger" aria-hidden="true" ></i>&nbsp;&nbsp;<UncontrolledTooltip target={"isBlocked" + i}>Blocked</UncontrolledTooltip></>}</label>
                                                            <span className="bos-custom-tooltip-header">
                                                                <i className="fa fa-at" aria-hidden="true"></i>&nbsp;&nbsp;{lead.node.email}&nbsp;{lead.node.hasDuplicateEmail && <i className="fa fa-exclamation-circle text-danger"></i>}<br />
                                                                <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;{lead.node.phone}&nbsp;{lead.node.hasDuplicatePhone && <i className="fa fa-exclamation-circle text-danger"></i>}<br />
                                                            </span>
                                                            <p className="bos-custom-tooltip-body">
                                                                <b>Type:</b> {toTitleCase(lead.node.leadType)}<br />
                                                                {lead.node.source && <><b>Source:</b> {lead.node.source}<br /></>}
                                                                {lead.node.referredBy && <><b>Referred By:</b> {lead.node.referredBy}<br /></>}
                                                                {lead.node.customer && lead.node.customer.dob && <><b>DOB:</b> {lead.node.customer.dob}<br /></>}
                                                                {lead.node.customer && lead.node.customer.preferredLanguage && <><b>Language:</b> {this.renderLangCode(lead.node.customer.preferredLanguage)} <br /></>}
                                                            </p>
                                                            <span className="bos-custom-tooltip-footer">
                                                                {lead.node.customer && <><Button onClick={() => this.toggleModal("MassMessageModal" + i)}><i className="fa fa-comment-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                                                {lead.node.customer && <><Button onClick={() => this.toggleModal("driverDocuments" + i)}><i className="fa fa-folder-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                                                {lead.node.isBlocked &&
                                                                    <>
                                                                        <Button style={{ backgroundColor: "red", color: "white" }} onClick={() => this.toggleModal("archiveLead" + i)} disabled={this.props.hasPermissions && !this.props.hasPermissions.includes("delete_lead")}><i class="fa fa-trash-o" aria-hidden="true" id={"archiveLeadTip" + i}></i></Button>&nbsp;&nbsp;
                                                                        {this.props.hasPermissions && !this.props.hasPermissions.includes("delete_lead") ?
                                                                            <UncontrolledTooltip target={"archiveLeadTip" + i}>Not enough permissions to perform this action</UncontrolledTooltip> :
                                                                            <UncontrolledTooltip target={"archiveLeadTip" + i}>Archive Lead</UncontrolledTooltip>
                                                                        }
                                                                    </>
                                                                }
                                                                <Button onClick={() => this.toggleModal("LeadInfoModal" + i)}><i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;&nbsp;More Info</Button>
                                                            </span>
                                                        </UncontrolledTooltip>}
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <span id={"leadInfo" + i} className="application-driver-name limit-text">
                                                                <a className="bos-custom-link" href={lead.node.customer && ('/driver/' + lead.node.customer.id)} target="_blank">
                                                                    {["Open", "Contacted", "Need Review"].includes(toTitleCase(lead.node.status)) ? lead.node.fullName.trim() ? lead.node.fullName : <span style={{ color: "#ff0000" }}>No Name Found</span> : lead.node.customer && lead.node.customer.name.trim() ? lead.node.customer.name : <span style={{ color: "#ff0000" }}>No Name Found</span>}
                                                                </a>
                                                            </span>
                                                            {(lead.node.hasDuplicateEmail || lead.node.hasDuplicatePhone) && <>&nbsp;&nbsp;
                                                                <i id={"duplicate" + i} className="fa fa-exclamation-circle text-danger"></i>
                                                                <UncontrolledTooltip target={"duplicate" + i}>{lead.node.hasDuplicateEmail && "Email exists for this driver"}<br />{lead.node.hasDuplicatePhone && "Phone Number exists for this driver"}</UncontrolledTooltip>
                                                            </>}
                                                            {lead.node.isBlocked && <>
                                                                &nbsp;&nbsp;<i id={"isBlocked" + i} class="fa fa-ban text-danger" aria-hidden="true" ></i>
                                                                <UncontrolledTooltip target={"isBlocked" + i}>Blocked</UncontrolledTooltip>
                                                            </>}
                                                            {lead.node.customer && lead.node.customer.inOffice && <>&nbsp;&nbsp;
                                                                <InOffice id={"inOffice" + i} width={25} height={25} fill="#198754" />
                                                                <UncontrolledTooltip target={"inOffice" + i}>Driver in Office</UncontrolledTooltip>
                                                            </>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        }
                                        {column.id === "status" && (!this.props.statuses || !(this.props.statuses.length === 1 && this.props.statuses.includes("Onboarding"))) &&
                                            <td>
                                                <div className="d-flex">
                                                    {["Contacted", "Open"].includes(toTitleCase(lead.node.status)) && !lead.node.isArchived && <span className='pr-3 mr-3 align-self-center' style={{ borderRight: "1px solid #e1e1e1" }}>
                                                        <i className={"fa fa-pencil fa-lg"} onClick={() => this.toggleModal("leadStatus" + i)} id={"leadStatus" + i}></i>
                                                        {this.state.openModal === "leadStatus" + i && <UpdateLeadInfo
                                                            id={lead.node.id}
                                                            isOpen={this.state.openModal === "leadStatus" + i}
                                                            type="select"
                                                            handleClose={() => this.toggleModal("leadStatus" + i)}
                                                            name="status"
                                                            target={"leadStatus" + i}
                                                            title="Update Lead Status"
                                                            refetchQuery={this.props.refetchAllCustomerLeadsQuery}
                                                            defaultValue={lead.node && lead.node.status ? { value: lead.node.status, label: lead.node.status } : null}
                                                        />}
                                                    </span>}
                                                    <span>
                                                        <Badge className="lead-status-badge" color={["Contacted", "Onboarding"].includes(toTitleCase(lead.node.status)) ? "success" : toTitleCase(lead.node.status) === "Open" ? "primary" : "danger"}>{toTitleCase(lead.node.status)}</Badge>
                                                    </span>
                                                </div>
                                            </td>
                                        }
                                        {column.id === "missing_docs" && !this.props.statuses.includes("Need Review") &&
                                            <td>
                                                {lead.node.customer ?
                                                    this.props.statuses.includes("Onboarding") ?
                                                        lead.node.customer.missingInsuranceDocuments && lead.node.customer.missingInsuranceDocuments.length > 0 ?
                                                            lead.node.customer.missingInsuranceDocuments.map((doc, i) => <><Badge color="danger" key={i}>{doc.typeName}</Badge>&nbsp;&nbsp;</>)
                                                            : <i className="fa fa-check-circle" aria-hidden="true" style={{ color: "green", fontSize: "15px" }} />
                                                        : lead.node.customer.missingApplicationDocuments && lead.node.customer.missingApplicationDocuments.length > 0 ?
                                                            lead.node.customer.missingApplicationDocuments.map((doc, i) => <><Badge color="danger" key={i}>{doc.typeName}</Badge>&nbsp;&nbsp;</>)
                                                            : <i className="fa fa-check-circle" aria-hidden="true" style={{ color: "green", fontSize: "15px" }} />
                                                    : "--"}
                                            </td>
                                        }
                                        {column.id === "notes" &&
                                            <td>
                                                <small>{lead.node.notes || "--"}</small>
                                                <br />
                                                <i id={"notesEdit" + i} onClick={() => this.toggleModal("notesEdit" + i)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                                <i id={"notesHistory" + i} onClick={() => this.toggleModal("notesHistory" + i)} className={"fa fa-history"}></i>
                                                {this.state.openModal === "notesEdit" + i && <UpdateLeadInfo id={lead.node.id} title="Edit Lead Notes" name="notes" defaultValue={lead.node.notes}
                                                    target={"notesEdit" + i} type="textarea" isOpen={this.state.openModal === "notesEdit" + i}
                                                    handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchAllCustomerLeadsQuery}
                                                />}
                                                {this.state.openModal === "notesHistory" + i && <DriverNotes driver={lead.node.customer} title="Lead Notes History" noteType="LEAD" objectId={lead.node.id} target={"notesHistory" + i} open={this.state.openModal === "notesHistory" + i} handleClose={() => this.toggleModal("")} />}
                                            </td>
                                        }
                                        {column.id === "branch" &&
                                            <td>
                                                <Badge className='bos-badge-blue'>{lead.node.branch.name}</Badge>
                                            </td>
                                        }
                                        {column.id === "tags" && !this.props.statuses.includes("Need Review") &&
                                            <td>
                                                {lead.node.customer && <div className="d-flex">
                                                    <span className='pr-3 align-self-center'>
                                                        <i className={"fa fa-pencil fa-lg"} onClick={() => this.toggleModal("drivertags" + i)} id={"drivertags" + i}></i>
                                                        {this.state.openModal === "drivertags" + i && <DriverUpdateTagsPopover
                                                            isOpen={this.state.openModal === "drivertags" + i}
                                                            target={"drivertags" + i}
                                                            handleClose={() => this.toggleModal("")}
                                                            driverId={lead.node.customer.id}
                                                            driverTags={lead.node.customer.tags}
                                                            refetchQuery={this.props.refetchAllCustomerLeadsQuery}
                                                        />}
                                                    </span>
                                                    <span className='pl-3' style={{ maxWidth: "150px", borderLeft: "1px solid #e1e1e1" }}>
                                                        {lead.node.customer.tags && lead.node.customer.tags.length > 0 ? lead.node.customer.tags.map((tag, i) =>
                                                            <>&nbsp;&nbsp;<Badge key={i} style={{ background: `#${tag.color ? tag.color : "grey"}` }}>{tag.name}</Badge></>
                                                        ) : <small className="text-danger">No Tags Found</small>}
                                                    </span>
                                                </div>}
                                            </td>
                                        }
                                        {column.id === "assigned_to" &&
                                            <td>
                                                <div className="d-flex">
                                                    <span className='pr-3 align-self-center'>
                                                        <i className={"fa fa-pencil fa-lg"} onClick={() => this.toggleModal("updateLeadAssignedRep" + i)} id={"assignedRep" + i}></i>
                                                        {this.state.openModal === "updateLeadAssignedRep" + i &&
                                                            <UpdateLeadInfo
                                                                id={lead.node.id}
                                                                isOpen={this.state.openModal === "updateLeadAssignedRep" + i}
                                                                type="select"
                                                                handleClose={() => this.toggleModal("updateLeadAssignedRep" + i)}
                                                                name="assignedRep"
                                                                target={"assignedRep" + i}
                                                                title="Update Assigned Rep"
                                                                groups={["Sales Team"]}
                                                                refetchQuery={this.props.refetchAllCustomerLeadsQuery}
                                                                defaultValue={lead.node.assignedRep ? { value: lead.node.assignedRep.id, label: lead.node.assignedRep.firstName ? lead.node.assignedRep.firstName + " " + lead.node.assignedRep.lastName : lead.node.assignedRep.username } : null}
                                                            />
                                                        }
                                                    </span>
                                                    <span className="pl-3" style={{ borderLeft: "1px solid #e1e1e1" }}>
                                                        {lead.node.assignedRep ?
                                                            <span>{lead.node.assignedRep.username}</span> : "--"
                                                        }
                                                    </span>
                                                </div>
                                            </td>
                                        }
                                    </>
                                )}
                            </tr>) :
                            !this.props.leadListLoading &&
                            <tr>
                                <th scope="row">&nbsp;</th>
                                <td colSpan={7}>No Lead Found</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </PrimaryListingContainer>
        )
    }
}
export default compose(
    withApollo,
    graphql(AllCustomerLeadsQuery, {
        options: ({ searchTerm, assignedTo, orderBy, driverTags, statuses, isArchived, leadTypes, inOffice, isBlocked }) => ({
            variables: {
                settingsKeyNames: ["ENABLE_PALENCA_WORKFLOW"],
                searchTerm,
                assignedTo,
                orderBy,
                statuses,
                isArchived,
                inOffice,
                isBlocked,
                leadTypes,
                driverTags,
                first: 30,
                loadMissingInsuranceDocs: statuses && statuses.includes("Onboarding") ? true : false,
                loadMissingApplicationDocs: statuses && !statuses.includes("Need Review") && !statuses.includes("Onboarding") ? true : false,
            },
            notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only'
        }),
        // This function re-runs every time `data` changes, including after `updateQuery`,
        // meaning our loadMoreEntries function will always have the right cursor
        props({ data: { loading, allCustomerLeads, fetchMore, searchTerm, variables } }) {
            return {
                leadListLoading: loading,
                allCustomerLeads,
                variables,
                refetchAllCustomerLeadsQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = allCustomerLeads && allCustomerLeads.edges ? allCustomerLeads.edges.length : 30
                    return fetchMore({
                        query: AllCustomerLeadsQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allCustomerLeads.edges
                            const pageInfo = fetchMoreResult.allCustomerLeads.pageInfo
                            return {
                                allCustomerLeads: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allCustomerLeads.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllCustomerLeadsQuery,
                        variables: {
                            cursor: allCustomerLeads.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allCustomerLeads.edges
                            const pageInfo = fetchMoreResult.allCustomerLeads.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                allCustomerLeads: {
                                    edges: [...previousResult.allCustomerLeads.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allCustomerLeads.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    }),
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["delete_lead"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    }),
)(LeadsList)
