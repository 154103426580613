import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress, Alert } from 'reactstrap'
import Select from 'react-select'
import moment from 'moment'

const UpdateInsuranceMutation = gql`
mutation UpdateInsuranceMutation($input: UpdateInsuranceStatusMutationInput!){
    updateInsuranceStatus(input:$input){
    ok
    errors{
        field
        messages
    }
}
} `

const AllDriverDocumentTypesQuery = gql`query AllDriverDocumentTypesQuery($driverId:ID){
    allDriverDocumentTypes(isActive:true, driverId: $driverId){
        edges{
            node{
              typeName
              id
            }
        }
    }
}
`


const AllDeniedReason = gql`
    query AllInsuranceReasonCategory{
        allInsuranceReasonCategory(isActive:true){
            edges{
                node{
                    id
                    title
                    dateAdded
                    dateModified
                    insurancereasonSet(isActive:true){
                        edges{
                            node{
                                id
                                title
                                description
                                isActive
                            }
                        }
                    }
                }
            }
      }
    }
`

const AllRemovedReasonTypes = gql`
    query AllRemovedReasonTypes{
        allRemovedReasonTypes(isActive:true){
            edges{
                node{
                    id
                    reasonName
                }
            }
      }
    }
`

const AllDriverInsuranceType = gql`
    query AllDriverInsuranceType($brokerId: ID!){
        allDriverInsuranceType(isActive:true,brokerId:$brokerId){
            edges{
                node{
                  id
                  name
                }
            }
      }
    }
`

const AllReps = gql`
     query AllRepsQuery($groups:[String], $orderBy:[String]){
        allReps(isStaff:true, isActive:true, groups:$groups, orderBy:$orderBy){
        edges{
          node{
            id
            username
          }
        }
      }
    }
`
class UpdateInsuranceInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            input: {},
            loading: false
        }
    }

    updateInsuranceStatus = () => {
        if (this.props.insuranceId) {
            this.setState({ loading: true })
            let input = this.state.input
            input["insuranceStatusId"] = this.props.insuranceId

            this.props.client.mutate({
                mutation: UpdateInsuranceMutation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateInsuranceStatus && result.data.updateInsuranceStatus.ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                    this.setState({ input: {}, loading: false, error: null })
                } else if (result.data.updateInsuranceStatus && result.data.updateInsuranceStatus.errors) {
                    this.setState({ loading: false, error: String(result.data.updateInsuranceStatus.errors[0].messages) })
                } else {
                    this.setState({ loading: false, error: "An error has occurred. Please check your input or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin." })
            })
        } else {
        }
    }


    updateInput = (e) => {
        if (this.props.insuranceId) {
            let name = e.target.name
            let value = e.target.value
            if (e.target.type === "number") {
                if (isNaN(parseFloat(value))) {
                    value = 0
                } else {
                    value = parseFloat(value)
                }
            }
            let input = this.state.input
            input[name] = value
            this.setState({ input: input })
        }
    }

    componentDidMount() {
        if (this.props.type === "boolean") {
            this.setState({ input: { [this.props.name]: this.props.value } })
        }
    }

    render() {
        return (
            <div>
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose} style={{ minWidth: this.props.name === "insuranceReasonId" ? "500px" : "300px" }}>
                    <PopoverHeader onClick={this.props.handleClose}>{this.props.title} <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                    <PopoverBody>
                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        <FormGroup>
                            <Label for={this.props.name}>{this.props.title}</Label>
                            {this.props.type === "select" && this.props.name === "extraDocsRequired" ?
                                <Select
                                    className="bos-custom-select" classNamePrefix="bos-select"
                                    isLoading={!(this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0)}
                                    options={this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 && this.props.allDriverDocumentTypes.edges.map(item => ({ value: item.node.id, label: item.node.typeName }))}
                                    defaultValue={this.props.value && this.props.value.edges && this.props.value.edges.length > 0 ? this.props.value.edges.map(item => ({ value: item.node.id, label: item.node.typeName })) : null}
                                    placeholder="Driver Document Types"
                                    isMulti
                                    isClearable={false}
                                    onChange={(docTypes) => this.setState({ input: { [this.props.name]: docTypes.map(item => item.value) } })} />
                                : this.props.type === "select" && this.props.name === "insuranceReasonId" ?
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!(this.props.allInsuranceReasonCategory && this.props.allInsuranceReasonCategory.edges && this.props.allInsuranceReasonCategory.edges.length > 0)}
                                        options={
                                            this.props.allInsuranceReasonCategory && this.props.allInsuranceReasonCategory.edges && this.props.allInsuranceReasonCategory.edges.length > 0 &&
                                            this.props.allInsuranceReasonCategory.edges.filter((item) => item.node.insurancereasonSet && item.node.insurancereasonSet.edges && item.node.insurancereasonSet.edges.length > 0).map((reasonCategory) => ({
                                                label: reasonCategory.node.title,
                                                options: reasonCategory.node.insurancereasonSet.edges.map((reason) => ({
                                                    label: reason.node.title,
                                                    value: reason.node.id,
                                                })),
                                            }))
                                        }
                                        defaultValue={this.props.value ? [{ value: this.props.value.id, label: this.props.value.title }] : null}
                                        placeholder="Update Denied Reason"
                                        onChange={(deniedReasons) => this.setState({ input: { [this.props.name]: deniedReasons.value } })} />
                                    : this.props.type === "select" && this.props.name === "removedReasonId" ?
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            isLoading={!(this.props.allRemovedReasonTypes && this.props.allRemovedReasonTypes.edges && this.props.allRemovedReasonTypes.edges.length > 0)}
                                            options={this.props.allRemovedReasonTypes && this.props.allRemovedReasonTypes.edges && this.props.allRemovedReasonTypes.edges.length > 0 && this.props.allRemovedReasonTypes.edges.map(item => ({ value: item.node.id, label: item.node.reasonName }))}
                                            defaultValue={this.props.value && this.props.value.edges && this.props.value.edges.length > 0 ? this.props.value.edges.map(item => ({ value: item.node.reason.id, label: item.node.reason.reasonName })) : null}
                                            placeholder="Update Removed Reason"
                                            onChange={(removedReason) => this.setState({ input: { [this.props.name]: removedReason.value } })} />
                                        : this.props.type === "select" && this.props.name === "driverInsuranceType" ?
                                            <Select
                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                isLoading={!(this.props.allDriverInsuranceType && this.props.allDriverInsuranceType.edges && this.props.allDriverInsuranceType.edges.length > 0)}
                                                options={this.props.allDriverInsuranceType && this.props.allDriverInsuranceType.edges && this.props.allDriverInsuranceType.edges.length > 0 && this.props.allDriverInsuranceType.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                                defaultValue={this.props.value ? [{ value: this.props.value.id, label: this.props.value.name }] : null}
                                                placeholder="Update Insurance Type"
                                                onChange={(insuranceType) => this.setState({ input: { [this.props.name]: insuranceType.value } })} />
                                            : this.props.type === "select" && this.props.name === "representative" ?
                                                <Select
                                                    className="bos-custom-select" classNamePrefix="bos-select"
                                                    isLoading={!(this.props.allReps && this.props.allReps.edges && this.props.allReps.edges.length > 0)}
                                                    options={this.props.allReps && this.props.allReps.edges && this.props.allReps.edges.length > 0 && this.props.allReps.edges.map(item => ({ value: item.node.id, label: item.node.firstName ? item.node.firstName + " " + item.node.lastName : item.node.username }))}
                                                    defaultValue={this.props.value ? [{ value: this.props.value.id, label: this.props.value.firstName ? this.props.value.firstName + " " + this.props.value.lastName : this.props.value.username }] : null}
                                                    placeholder="Update Created By"
                                                    onChange={(reps) => this.setState({ input: { [this.props.name]: reps.value } })} />
                                                : this.props.type === "boolean" ?
                                                    <>
                                                        <br />
                                                        <div>
                                                            <span>Yes</span>
                                                            <div className="toggle-switch">
                                                                <input
                                                                    type="checkbox"
                                                                    className="toggle-switch-checkbox"
                                                                    name={this.props.name}
                                                                    id={"branchInfo"}
                                                                    checked={!this.state.input[this.props.name]}
                                                                    onChange={() => this.setState({ input: { [this.props.name]: !this.state.input[this.props.name] } })}
                                                                />
                                                                <label className="toggle-switch-label" htmlFor={"branchInfo"}>
                                                                    <span className="toggle-switch-inner" />
                                                                    <span className="toggle-switch-switch" />
                                                                </label>
                                                            </div>
                                                            <span>No</span>
                                                        </div>
                                                    </>
                                                    : this.props.type === "date" ?
                                                        <Input defaultValue={this.props.value} type={this.props.type} name={this.props.name}
                                                            id={this.props.id} onChange={this.updateInput} placeholder={this.props.title}
                                                            min={moment().format('YYYY-MM-DD')}
                                                        />
                                                        : <Input autoComplete={"off"} type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200}
                                                            name={this.props.name} id={this.props.name} defaultValue={this.props.value}
                                                            placeholder={this.props.title} onChange={this.updateInput}
                                                        />
                            }
                        </FormGroup>
                        {this.state.loading ? <Progress animated color="info" value={100} /> :
                            <Button type="button" size="sm" onClick={this.updateInsuranceStatus} disabled={!(this.props.insuranceId)}>Submit</Button>
                        }
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllDriverDocumentTypesQuery, {
        options: ({ driverId }) => ({ variables: { driverId }, fetchPolicy: "cache-first", }),
        props({ data: { loading, allDriverDocumentTypes } }) { return { allDriverDocumentTypesLoading: loading, allDriverDocumentTypes } }
    }),
    graphql(AllDeniedReason, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allInsuranceReasonCategory } }) {
            return { loading, allInsuranceReasonCategory }
        },
    }),
    graphql(AllRemovedReasonTypes, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allRemovedReasonTypes } }) {
            return { loading, allRemovedReasonTypes }
        },
    }),
    graphql(AllDriverInsuranceType, {
        options: ({ brokerId }) => ({ variables: { brokerId: brokerId }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
        props({ data: { loading, allDriverInsuranceType } }) {
            return { loading, allDriverInsuranceType }
        },
    }),
    graphql(AllReps, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allReps } }) {
            return { loading, allReps }
        },
    }),

)(UpdateInsuranceInfo)
