import gql from 'graphql-tag'

const InstallCarEquipment = gql`
    mutation InstallCarEquipment($input: CreateEquipmentMutationInput!){
        createEquipment(input: $input){
            ok
            errors{
                field
                messages
            }
        }
}`

const UpdateEquipmentMutation = gql`
    mutation UpdateEquipment($input: UpdateEquipmentMutationInput!){
        updateEquipment(input: $input){
            ok
            errors{
              messages
            }
          }
}`


const UpdateCarPolicyMutation = gql`
    mutation updateCarPolicyMutation($input: UpdateCarPolicyMutationInput!){
        response:updateCarPolicy(input:$input){
            ok
            errors{
                messages
            }
    }
} `

const RemoveEquipment = gql`
    mutation RemoveEquipment($input: RemoveEquipmentMutationInput!){
        removeEquipment(input: $input){
            ok
            errors{
                field
                messages
            }
        }
    }`

const RemoveCar = gql`
    mutation RemoveCar($input: RemoveCarMutationInput!){
        removeCar(input: $input){
            ok
            errors{
                field
                messages
            }
        }
    }`

const UpdateCarMutation = gql`
    mutation updateCarMutation($input: UpdateCarMutationInput!){
        updateCar(input:$input){
            ok
            errors{
                field
                messages
            }
        }
    } `

const CreateDailyMileageMutation = gql`
    mutation CreateDailyMileageMutation($input: CreateDailyMileageMutationInput!){
        createDailyMileage(input:$input){
            ok
            errors{
                field
                messages
            }
        }
    } `


const locateCar = gql`
    mutation locateCar($input:  TrackerStatusChangeMutationInput!){
        changeTrackerStatus(input: $input){
            ok
            errors{
                field
                messages
            }
            car {
                id
                pk
                trackerStatus
                hasTracker
            }
            location{
                address
                longitude
                latitude
                speed
                date
            }
        }
    }`

const updateTracker = gql`
    mutation UpdateTracker($input:  TrackerStatusChangeMutationInput!){
        changeTrackerStatus(input: $input){
            ok
            errors{
                field
                messages
            }
            car {
                id
                pk
                trackerStatus
                hasTracker
                dmvPlate
                vin
                model
                color

            }
        }
    }`

export {
    InstallCarEquipment,
    UpdateCarPolicyMutation,
    RemoveEquipment,
    UpdateEquipmentMutation,
    RemoveCar,
    UpdateCarMutation,
    updateTracker,
    locateCar,
    CreateDailyMileageMutation
}