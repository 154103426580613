import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql, withApollo } from 'react-apollo'
import { Container, Col, Row, Label, Input, FormGroup, Alert, Button, Table } from 'reactstrap'
import Loader from "../../../Material/Loader"
import { AllGroups, AllGroupsBasedOnPermission } from "../../Queries"
import { Link } from 'react-router-dom'
import "./OverviewTab.css"
const UpdatePermissionMutation = gql`
mutation UpdatePermission($input: UpdatePermissionMutationInput!){
    updatePermission(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const AllMembers = gql`query AllMembers{
    allMembers {
        edges {
            node {
                id
                user{
                    id
                    username
                    firstName
                    lastName
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`

const PermissionDetailQuery = gql`query permission($id: ID!){
    permission(id: $id) {
        id
        name
        codename
        contentType {
            id
            model
            appLabel
        }
        groupSet {
            edges {
                node {
                    id
                    name
                }
            }
        }
        userSet {
            edges {
                node {
                    id
                    username
                }
            }
        }
    }
  }  
`

class OverviewTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            loading: false,
            assignedGroups: [],
            assignedUsers: [],
            groupSearchTerm: "",
            userSearchTerm: "",
            disableUpdateBtn: true,
            input: {}
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    handleAdd = (sourceId, stateKey) => {
        const selectedElement = document.getElementById(sourceId)
        if (selectedElement) {
            const selectedOptions = Array.from(selectedElement.options).filter((option) => option.selected).map((option) => ({
                value: option.value,
                label: option.text,
            }))
            const updatedState = [...this.state[stateKey], ...selectedOptions]
            const updatedStateKeys = { [stateKey]: updatedState }
            updatedStateKeys["disableUpdateBtn"] = false

            this.setState(updatedStateKeys)
        } else {
            console.error(`Element with ID ${sourceId} does not exist.`)
        }
    };

    handleRemove = (sourceId, stateKey) => {
        const selectedElement = document.getElementById(sourceId)
        if (selectedElement) {
            const selectedOptions = Array.from(selectedElement.options).filter((option) => option.selected).map((option) => option.value)
            const updatedState = this.state[stateKey].filter((item) => !selectedOptions.includes(item.value))
            const updatedStateKeys = { [stateKey]: updatedState }
            updatedStateKeys["disableUpdateBtn"] = false

            this.setState(updatedStateKeys)
        } else {
            console.error(`Element with ID ${sourceId} does not exist.`)
        }
    };

    updatePermission = () => {
        if (this.props.permission && this.props.permission.id) {
            this.setState({ loading: true })
            let input = this.state.input
            input["permissionId"] = this.props.permission && this.props.permission.id
            if (this.state.assignedGroups)
                input["groups"] = this.state.assignedGroups.map(item => { return item.value })
            if (this.state.assignedUsers)
                input["users"] = this.state.assignedUsers.map(item => { return item.value })
            this.props.client.mutate({
                mutation: UpdatePermissionMutation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updatePermission && result.data.updatePermission.ok) {
                    this.props.refetchQuery()
                    this.props.refetchPermissionBasedGroupQuery()
                    this.setState({ input: {}, loading: false, error: null })
                } else if (result.data.updatePermission && result.data.updatePermission.errors) {
                    this.setState({ loading: false, error: String(result.data.updatePermission.errors[0].messages) })
                } else {
                    this.setState({ loading: false, error: "An error has occurred. Please check your input or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin." })
            })
        } else {
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.permission !== prevProps.permission) {
            let assignedGroupsArr = []
            let assignedUsersArr = []
            if (this.props.permission && this.props.permission.groupSet && this.props.permission.groupSet.edges && this.props.permission.groupSet.edges.length > 0) {
                this.props.permission.groupSet.edges.map((item) =>
                    assignedGroupsArr.push({ value: item.node.id, label: item.node.name })
                )
            }
            if (this.props.permission && this.props.permission.userSet && this.props.permission.userSet.edges && this.props.permission.userSet.edges.length > 0) {
                this.props.permission.userSet.edges.map((item) =>
                    assignedUsersArr.push({ value: item.node.id, label: item.node.firstName ? item.node.firstName + " " + item.node.lastName : item.node.username })
                )
            }
            this.setState({ assignedGroups: assignedGroupsArr, assignedUsers: assignedUsersArr })
        }
    }

    renderTableRows = () => {
        const usersGroupsMap = {}
        this.props.permissionBasedGroups && this.props.permissionBasedGroups.edges && this.props.permissionBasedGroups.edges.length > 0 && this.props.permissionBasedGroups.edges.map(group => {
            group.node.userSet.edges.forEach(user => {
                const userName = user.node.username
                if (!usersGroupsMap[userName]) {
                    usersGroupsMap[userName] = []
                }
                usersGroupsMap[userName].push(group.node.name)
            })
        })

        return Object.keys(usersGroupsMap) && Object.keys(usersGroupsMap).length > 0 ? Object.keys(usersGroupsMap).map((userName, index) => (
            <tr key={index}>
                <td>{userName}</td>
                <td>
                    <span>
                        {usersGroupsMap[userName].map((groupName, groupIndex) => (
                            <span key={groupIndex}>{groupName + ",  "}</span>
                        ))}
                    </span>
                </td>
            </tr>
        ))
            : "No Data Found!"
    };

    render() {
        const assignedGroupsIds = this.state.assignedGroups.map(group => group.value)
        const assignedUsersIds = this.state.assignedUsers.map(group => group.value)
        return (
            <Container fluid>
                {this.props.loading || this.state.loading || !this.props.permission ? <Loader /> : <>
                    {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                    <Row>
                        <h4>&nbsp;&nbsp;&nbsp;PERMISSION INFO</h4>
                        <Col xs={12} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col xs={4}>
                                    <Label>Name</Label>
                                    <Input disabled className="box-object-property-input" value={this.props.permission.name ? this.props.permission.name : "--"} />
                                </Col>
                                <Col xs={4}>
                                    <Label>Code Name</Label>
                                    <Input disabled className="box-object-property-input" value={this.props.permission.codename ? this.props.permission.codename : "--"} />
                                </Col>
                                <Col xs={4}>
                                    <Label>Content Type</Label>
                                    <Input disabled className="box-object-property-input" value={(this.props.permission.contentType ? this.props.permission.contentType.appLabel + " | " + this.props.permission.contentType.model : "--")} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col>
                                    <Row>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>AVAILABLE GROUPS</h4></b></Label>
                                                <Input className="mb-2" name="groupSearchTerm" id="groupSearchTerm" onChange={(e) => this.setState({ groupSearchTerm: e.target.value })} placeholder="Search Groups" />
                                                <Input
                                                    type="select"
                                                    name="selectedGroupIds"
                                                    id="selectedGroupIds"
                                                    multiple
                                                    style={{ height: "300px" }}
                                                >
                                                    {this.props.allGroups && this.props.allGroups.edges && this.props.allGroups.edges.length > 0 && this.props.allGroups.edges.filter(item => !assignedGroupsIds.some(groupId => groupId === item.node.id))
                                                        .filter(item => {
                                                            if (this.state.groupSearchTerm) {
                                                                return item.node.name.toLowerCase().includes(this.state.groupSearchTerm.toLowerCase())
                                                            } else {
                                                                return true
                                                            }
                                                        })
                                                        .map((item) =>
                                                            <option key={item.node.id} value={item.node.id}>{item.node.name}</option>
                                                        )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={2} className="arrow-btn-container">
                                            <Button onClick={() => this.handleAdd("selectedGroupIds", "assignedGroups")} className="arrow-btn">
                                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                            </Button>
                                            <br />
                                            <Button onClick={() => this.handleRemove("groups", "assignedGroups")} className="arrow-btn">
                                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                            </Button>
                                        </Col>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>ASSIGNED GROUPS</h4></b></Label>
                                                <Input
                                                    type="select"
                                                    name="groups"
                                                    id="groups"
                                                    multiple
                                                    style={{ height: "335px" }}
                                                >
                                                    {this.state.assignedGroups && this.state.assignedGroups.map((item) =>
                                                        <option key={item && item.value} value={item && item.value}>{item && item.label}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={6} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col>
                                    <Row>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>ALL USERS</h4></b></Label>
                                                <Input className="mb-2" name="userSearchTerm" id="userSearchTerm" onChange={(e) => this.setState({ userSearchTerm: e.target.value })} placeholder="Search By Username" />
                                                <Input
                                                    type="select"
                                                    name="selectedUserIds"
                                                    id="selectedUserIds"
                                                    multiple
                                                    style={{ height: "300px" }}
                                                >
                                                    {this.props.allMembers && this.props.allMembers.edges && this.props.allMembers.edges.length > 0 && this.props.allMembers.edges.filter(item => !assignedUsersIds.some(memberIds => memberIds === item.node.user.id))
                                                        .filter(item => {
                                                            if (this.state.userSearchTerm) {
                                                                return item.node.user.username.toLowerCase().includes(this.state.userSearchTerm.toLowerCase())
                                                            } else {
                                                                return true
                                                            }
                                                        })
                                                        .map((item) =>
                                                            <option key={item.node.user.id} value={item.node.user.id}>{item.node.user.username}</option>
                                                        )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={2} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                            <Button onClick={() => this.handleAdd("selectedUserIds", "assignedUsers")} className="arrow-btn">
                                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                            </Button>
                                            <br />
                                            <Button onClick={() => this.handleRemove("userIds", "assignedUsers")} className="arrow-btn">
                                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                            </Button>
                                        </Col>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>ASSIGNED USERS</h4></b></Label>
                                                <Input
                                                    type="select"
                                                    name="userIds"
                                                    id="userIds"
                                                    multiple
                                                    style={{ height: "335px" }}
                                                >
                                                    {this.state.assignedUsers && this.state.assignedUsers.map((item) =>
                                                        <option key={item && item.value} value={item && item.value}>{item && item.label}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="bos-object-section-wrapper">
                            <Row className="bos-object-section" style={{ maxHeight: "300px", overflow: "auto" }}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th><p style={{ fontSize: 15 }}>USER</p></th>
                                            <th><p style={{ fontSize: 15 }}>GROUPS</p></th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.renderTableRows()}</tbody>
                                </Table>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-end pr-5"><Button onClick={this.updatePermission} className="overview-primary-btn" disabled={this.state.disableUpdateBtn}>UPDATE</Button></Col>
                    </Row>
                </>}
            </Container>
        )
    }
}

export default compose(
    withApollo,
    graphql(PermissionDetailQuery, {
        options: ({ permissionId }) => ({
            variables: { id: permissionId },
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
        }),
        props({ data: { permission, loading, refetch, variables } }) {
            return {
                permission, loading, variables,
                refetchQuery: () => {
                    return refetch({
                        query: PermissionDetailQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { permission: fetchMoreResult.permission }
                        },
                    })
                },
            }
        }
    }),
    graphql(AllGroupsBasedOnPermission, {
        options: ({ permissionId }) => ({
            variables: { permissionId: permissionId },
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
        }),
        props({ data: { permissionBasedGroups, loading, refetch, variables } }) {
            return {
                permissionBasedGroups, loading, variables,
                refetchPermissionBasedGroupQuery: () => {
                    return refetch({
                        query: AllGroupsBasedOnPermission,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { permissionBasedGroups: fetchMoreResult.permissionBasedGroups }
                        },
                    })
                },
            }
        }
    }),
    graphql(AllGroups, { props({ data: { allGroups, loading } }) { return { allGroups, allGroupsLoading: loading } } }),
    graphql(AllMembers, { props({ data: { allMembers, loading } }) { return { allMembers, allMembersLoading: loading } } }),
)(OverviewTab)
