import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import {
    Row, Col, Alert, Progress, Label, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import moment from 'moment'

const updateInsuranceStatusMutation = gql`
mutation updateInsuranceStatusMutation($input: UpdateInsuranceStatusMutationInput!){
    updateInsuranceStatus(input:$input){
    ok
    errors{
        messages
    }
}
} `
const AllDriverInsuranceType = gql`
    query AllDriverInsuranceType{
        allDriverInsuranceType(isActive:true){
            edges{
                node{
                  id
                  name
                  surcharge
                  deposit
                  broker{
                    id
                    name
                    isActive
                  }
                }
            }
      }
    }
`

class ApproveInsurance extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            insuranceApproved: false,
            openModal: "",
            surcharge: 0,
            deposit: 0,
            reason: null,
            driverInsuranceType: null,
        }
    }
    handleClose = (e) => {
        this.props.handleClose()
    };
    approveInsurance = () => {

        try {
            this.setState({ loading: true })
            let input = {
                insuranceStatusId: this.props.insurance.id,
                driverInsuranceType: this.state.driverInsuranceType,
                status: 1,
                surcharge: this.state.surcharge,
                deposit: this.state.deposit,
            }
            this.props.client.mutate({
                mutation: updateInsuranceStatusMutation,
                variables: { input },
            }).then((result) => {
                if (result.data && result.data.updateInsuranceStatus && result.data.updateInsuranceStatus.ok) {
                    this.setState({ insuranceApproved: true, error: null, loading: false })
                    this.props.refetchDriverQuery && this.props.refetchDriverQuery()
                    this.props.refetchListingQuery && this.props.refetchListingQuery()
                    this.props.handleClose()
                } else {
                    this.setState({ loading: false, insuranceApproved: false, error: result.data.updateInsuranceStatus.errors[0].messages[0] })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured"
                this.setState({ error: errorMessage, loading: false, insuranceApproved: false, })
            })
        } catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false, insuranceApproved: false, })
        }
    };
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        // To change string inputs to boolean because only strings can be passed to html options
        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        if (name === "driverInsuranceType" && this.props.allDriverInsuranceType && this.props.allDriverInsuranceType.edges) {
            let selectedTypeNode = this.props.allDriverInsuranceType.edges.filter((insuranceSatusType, i) => insuranceSatusType.node.id === value)
            let surcharge = 0
            let deposit = 0
            if (selectedTypeNode && selectedTypeNode.length > 0) {
                surcharge = selectedTypeNode[0].node.surcharge
                deposit = selectedTypeNode[0].node.deposit
                this.setState({ surcharge: surcharge, deposit: deposit })
            }
        }
        this.setState({ [name]: value })
    };
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return (
            <div>
                <Modal className="approveInsurance" isOpen={this.props.open}>
                    {this.props.driverId &&
                        <>
                            <ModalHeader>Approve Insurance</ModalHeader>
                            <ModalBody>
                                {this.state.error && !this.state.insuranceApproved && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                                {this.state.insuranceApproved && !this.state.error && <Row><Col xs={12}><Alert color="success">Insurance approved successfully!</Alert></Col></Row>}
                                {!this.state.insuranceApproved &&
                                    <Row>
                                        <Col xs={12}>
                                            <FormGroup>
                                                <Label for="driverInsuranceType">Select Driver Insurance Type</Label>
                                                <Input type="select" name="driverInsuranceType" id="driverInsuranceType" onChange={this.updateInput} placeholder="Select Driver Insurance Type">
                                                    <option key={-1} value={null}>Choose Type</option>
                                                    {this.props.allDriverInsuranceType && this.props.allDriverInsuranceType.edges && (this.props.insurance || this.state.brokerId) &&
                                                        this.props.allDriverInsuranceType.edges.filter((insuranceSatusType, i) => (this.props.insurance && this.props.insurance.broker) ? insuranceSatusType.node.broker.id === this.props.insurance.broker.id : insuranceSatusType.node.broker.id === this.state.brokerId).map((insuranceType, i) =>
                                                            <option key={i} value={insuranceType.node.id} title={insuranceType.node.broker.name}>{insuranceType.node.name}</option>
                                                        )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        {this.state.driverInsuranceType &&
                                            <>
                                                <Col xs={12}>
                                                    <FormGroup>
                                                        <Label for="surcharge">How much is the driver surcharge?</Label>
                                                        <Input value={this.state.surcharge} min={0} type="number" name="surcharge" id="surcharge" onChange={this.updateInput} placeholder="How much is the driver surcharge?" />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12}>
                                                    <FormGroup>
                                                        <Label for="deposit">How much is the driver deposit?</Label>
                                                        <Input value={this.state.deposit} min={0} type="number" name="deposit" id="deposit" onChange={this.updateInput} placeholder="How much is the driver deposit?" />
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                }
                                {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                            </ModalBody>
                        </>}
                    <ModalFooter>
                        {!this.state.loading &&
                            <Row>
                                <Col xs={12}>
                                    <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button color="secondary" onClick={this.approveInsurance}
                                        disabled={this.state.insuranceApproved || this.state.loading || !this.state.driverInsuranceType}
                                    >Approve Insurance
                                    </Button>&nbsp;&nbsp;
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllDriverInsuranceType, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allDriverInsuranceType } }) {
            return { loading, allDriverInsuranceType }
        },
    }),
)(ApproveInsurance)